<template>
  <el-dialog class="dialog" :title="formTitle" :close-on-click-modal="false" :visible.sync="formVisible">
    <el-form ref="dataForm" :rules="rules" :model="formData" label-position="left" label-width="100px" style="width: 400px; margin-left: 50px">
      <el-form-item label="视频分类" prop="type">
        <el-select v-model="formData.type" filterable default-first-option placeholder="请选择视频分类" @change="selType">
          <el-option v-for="item in typeList" :key="item._id" :label="item.name" :value="item._id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="专题分类" prop="special">
        <el-select v-model="formData.special" filterable default-first-option placeholder="请选择视频分类">
          <el-option v-for="item in specialList" :key="item._id" :label="item.name" :value="item._id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="视频标题" prop="name">
        <el-input v-model="formData.name" clearable maxlength="60" placeholder="请输入视频标题" />
      </el-form-item>
      <el-form-item label="访问密码方式" prop="passwordType">
        <el-switch class="has-text" style="margin-left: 6px" v-model="formData.passwordShow" :width="36" :active-value="1" :inactive-value="0" active-text="需要密码" inactive-text="公开" @change="handleSwitch($event)"></el-switch>
        <div v-if="formData.passwordShow">
          <el-switch class="has-text" style="margin-left: 6px" v-model="formData.passwordType" :width="36" :active-value="1" :inactive-value="0" active-text="普通密码" inactive-text="独立密码" @change="handleSwitch($event)"></el-switch>
          <el-form-item label="视频独立密码" prop="password" v-if="formData.passwordType == 0">
            <el-input v-model="formData.password" clearable maxlength="60" placeholder="请输入视频独立密码" />
          </el-form-item>
        </div>
      </el-form-item>
      <el-form-item label="宣传图片" prop="image">
        <Imgpond v-model="formData.image" :count="1" />
      </el-form-item>
      <el-form-item label="视频地址" prop="image">
        <el-input v-model="formData.video" placeholder="请输入视频地址"></el-input>
      </el-form-item>
      <el-form-item label="图文详情" prop="content">
        <Edit></Edit>
        <!-- <MiniMCE v-if="formVisible" style="width: 700px" v-model="formData.content" /> -->
      </el-form-item>
      <el-form-item label="是否启用" prop="status">
        <el-switch class="has-text" style="margin-left: 6px" v-model="formData.status" :width="36" :active-value="1" :inactive-value="0" active-text="启用" inactive-text="禁用"></el-switch>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer" style="padding-left: 140px">
      <el-button size="medium" class="confirm-btn" @click="formVisible = false">取消</el-button>
      <el-button size="medium" class="confirm-btn" type="primary" @click="submit">提交</el-button>
    </div>
  </el-dialog>
</template>

<script>
import http from '@/utils/cloud'
import imageUpload from '@/components/image-upload/image-upload'
import Edit from '@/components/edit'
export default {
  components: {
    Edit
  },
  data() {
    return {
      project: localStorage.getItem('mall-project'),
      password: false, //添加密码的显示状态
      formVisible: false, //表单显示状态
      formData: {}, //表单数据
      typeList: [],
      specialList: [],
      rules: {
        type: [{ required: true, message: '请选择视频分类', trigger: 'blur' }],
        special: [{ required: false, message: '请选择专题', trigger: 'blur' }],
        name: [{ required: true, message: '请输入视频标题', trigger: 'blur' }],
        content: [{ required: true, message: '请编辑视频详情', trigger: 'blur' }],
        status: [{ required: true }],
        image: [{ required: true, message: '请上传图片', trigger: 'blur' }],
        video: [{ required: true, message: '请输入视频地址', trigger: 'blur' }]
      }
    }
  },
  computed: {
    formTitle() {
      return this.formData._id ? '修改视频' : '添加视频'
    }
  },
  watch: {
    formData(val) {
      this.$nextTick(() => {
        if (tinymce.activeEditor) {
          tinymce.activeEditor.setContent(val.content)
        } else {
          setTimeout(() => {
            tinymce.activeEditor.setContent(val.content)
          }, 1000)
        }
        this.getSpecial()
      })
    },
    formVisible(state) {
      if (state) {
        //默认数据
        if (!this.formData._id) {
          this.formData = {
            type: '',
            name: '',
            status: 1,
            passwordType: 1
          }
        } else if (this.formData.passwordType === 0) {
          this.password = true
        } else if (this.formData.passwordType === 1) {
          this.password = false
        }
      } else {
        this.$refs.dataForm.resetFields()
        this.formData = {}
      }
    }
  },
  created() {
    this.loadTypeList()
  },
  methods: {
    selType() {
      this.formData.special = ''
      this.specialList = []
      this.getSpecial()
    },
    handleSwitch(e) {
      if (e === 0) {
        this.password = true
      } else if (e === 1) {
        this.password = false
      }
    },
    async getSpecial() {
      const response = await http.POST('/jw-admin', {
        module: 'video',
        operation: 'getSpecialList',
        type: this.formData.type,
        project: JSON.parse(this.project)._id
      })
      this.specialList = response.data.filter(item => item.status === 1)
    },
    //加载分类
    async loadTypeList() {
      const response = await http.POST('/jw-admin', {
        module: 'video',
        operation: 'getCategoryList',
        project: JSON.parse(this.project)._id
      })
      this.typeList = response.data.filter(item => item.status === 1)
      this.getSpecial()
    },
    //提交
    submit() {
      this.formData.content = tinymce.activeEditor.getContent()
      this.$refs.dataForm.validate(async res => {
        if (res === false) {
          return
        }
        const operation = this.formData._id ? 'updateArticle' : 'addArticle'
        const response = await http.POST('/jw-admin', {
          module: 'video',
          operation: operation,
          project: JSON.parse(this.project)._id,
          ...this.formData
        })
        if (response.status === 1) {
          this.$message.success(response.msg)
          this.$emit('refreshData')
          this.formVisible = false
        } else {
          this.$message.error(response.msg)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog {
  & ::v-deep .el-dialog__body {
    padding-bottom: 10px;
  }
  & ::v-deep .el-dialog {
    margin-bottom: 5vh;
    min-width: 880px;
    max-width: 880px;
  }
}
</style>
